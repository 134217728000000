import * as Yup from 'yup';
import { useFormik } from 'formik';

import React from 'react';
import { useDispatch } from 'react-redux';

import { useIntl } from 'gatsby-plugin-intl';

import passwordReset from '../services/api/actions/passwordReset';
import {
  hasConsecutiveSymbols,
  hasSpecialCharacters,
  isClient,
  getInstanceName,
} from '../helpers/utils';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ArrowBack from '../components/arrowBack';
import Button from '../components/button';
import InputPassword from '../components/inputPassword';

import styles from '../styles/pages/forgot-password.module.scss';

const validationSchemaWithIntl = Yup.object().shape({
  newPassword: Yup.mixed()
    .test(
      'has-consecutive',
      'should not have 3 consecutive letters or numbers',
      hasConsecutiveSymbols
    )
    .test('has-special-characters', 'should have special characters', hasSpecialCharacters)
    .test('is-long-enough', 'minimum 10 characters long', (value) => value.length >= 10)
    .required('this field is required'),
  confirmPassword: Yup.string()
    .required('this field is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
});

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const CreateNewPasswordPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const fk = useFormik({
    initialValues,
    validationSchema: validationSchemaWithIntl,
    validateOnBlur: true,
    isInitialValid: false,
    onSubmit: (values) => {
      const token = isClient ? window.location.search.replace('?token=', '') : '';
      const data = {
        token,
        newPassword: values.newPassword,
      };
      dispatch(passwordReset.action(data));
    },
  });

  const titleTxt = intl.formatMessage({ id: 'createPass.createNewPassword' });
  const subTitleTxt = intl.formatMessage({ id: 'createPass.setASecurePassword' });
  const buttonTxt = intl.formatMessage({ id: 'createPass.confirm' });
  const backTxt = intl.formatMessage({ id: 'forgot_pass.back' });
  const typeInPassword = intl.formatMessage({ id: 'typeInPassword' });
  const newPassword = intl.formatMessage({ id: 'createPass.newPass' });
  const confirmPassword = intl.formatMessage({ id: 'createPass.confirmPassword' });
  const showErrorMessage = (id) => fk.touched[id] ? fk.errors[id] : '';

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.createNewPassword' });

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <ArrowBack text={backTxt} navigationTo="/login" />
          <div className={styles.header}>
            <h2 className={styles.title}>{titleTxt}</h2>
          </div>
          <form onSubmit={fk.handleSubmit} className={styles.content}>
            <div className={styles.wrapper}>
              <div className={styles.description}>{subTitleTxt}</div>
              <InputPassword
                label={newPassword}
                id="newPassword"
                value={fk.values.newPassword}
                onChange={fk.handleChange}
                onBlur={fk.handleBlur}
                errorMessage={showErrorMessage('newPassword')}
                fullWidth={false}
                placeholder={typeInPassword}
              />
              <InputPassword
                label={confirmPassword}
                id="confirmPassword"
                value={fk.values.confirmPassword}
                onBlur={fk.handleBlur}
                onChange={fk.handleChange}
                errorMessage={showErrorMessage('confirmPassword')}
                fullWidth={false}
                placeholder={typeInPassword}
              />
            </div>
            <div className={styles.action}>
              <Button fullWidth size="large" type="submit" disabled={!fk.isValid}>
                {buttonTxt}
              </Button>
            </div>
          </form>
        </div>
      </Layout>
    </>
  );
};

export default CreateNewPasswordPage;
